<template>
	<div class="grid dashboard">
		<div class="col-12 md:col-3">
			<div class="overview-box overview-box-1"><h1>SALES</h1>
				<div class="overview-value">$25,620</div>
				<div class="overview-ratio">
					<div class="overview-direction">
						<i class="pi pi-arrow-up"></i>
					</div>
					<div class="overview-ratio-value">
						51%
					</div>
				</div>
				<img src="layout/images/dashboard/graph-blue.svg" alt="apollo-layout"/>
			</div>
		</div>

		<div class="col-12 md:col-3">
			<div class="overview-box overview-box-2">
				<h1>VIEWS</h1>
				<div class="overview-value">9521</div>
				<div class="overview-ratio">
					<div class="overview-direction">
						<i class="pi pi-arrow-up"></i>
					</div>
					<div class="overview-ratio-value">
						36%
					</div>
				</div>
				<img src="layout/images/dashboard/graph-green.svg" alt="apollo-layout"/>
			</div>
		</div>

		<div class="col-12 md:col-3">
			<div class="overview-box overview-box-3">
				<h1>USERS</h1>
				<div class="overview-value">452</div>
				<div class="overview-ratio">
					<div class="overview-direction">
						<i class="pi pi-arrow-up"></i>
					</div>
					<div class="overview-ratio-value">
						19%
					</div>
				</div>
				<img src="layout/images/dashboard/graph-yellow.svg" alt="apollo-layout"/>
			</div>
		</div>

		<div class="col-12 md:col-3">
			<div class="overview-box overview-box-4">
				<h1>SOCIAL</h1>
				<div class="overview-value">65922</div>
				<div class="overview-ratio">
					<div class="overview-direction">
						<i class="pi pi-arrow-up"></i>
					</div>
					<div class="overview-ratio-value">
						25%
					</div>
				</div>
				<img src="layout/images/dashboard/graph-red.svg" alt="apollo-layout"/>
			</div>
		</div>

		<div class="col-12">
			<Panel header="Status" class="circle-panel">
				<div class="grid grid-nogutter">
					<div class="col-12 lg:col-3 md:col-6">
						<div class="status-title" style="color:#6ebc3b">Users</div>
						<div class="circle1">
							<i class="pi pi-user"></i>
							<span>75</span>
						</div>
					</div>
					<div class="col-12 lg:col-3 md:col-6">
						<div class="status-title" style="color:#f6a821">Mobile</div>
						<div class="circle2">
							<i class="pi pi-mobile"></i>
							<span>25</span>
						</div>
					</div>
					<div class="col-12 lg:col-3 md:col-6">
						<div class="status-title" style="color:#039ade">Pageviews</div>
						<div class="circle3">
							<i class="pi pi-eye"></i>
							<span>50</span>
						</div>
					</div>
					<div class="col-12 lg:col-3 md:col-6">
						<div class="status-title" style="color:#d66351">Sales</div>
						<div class="circle4">
							<i class="pi pi-dollar"></i>
							<span>75</span>
						</div>
					</div>
				</div>
			</Panel>
		</div>

		<div class="col-12 md:col-6 lg:col-4 task-list">
			<Panel header="Tasks">
				<ul>
					<li>
						<Checkbox name="task" value="reports" v-model="tasksCheckbox"/>
						<span class="task-name">Sales Reports</span>
						<i class="pi pi-briefcase"></i>
					</li>
					<li>
						<Checkbox name="task" value="pay" v-model="tasksCheckbox"/>
						<span class="task-name">Pay Invoices</span>
						<i class="pi pi-file"></i>
					</li>
					<li>
						<Checkbox name="task" value="dinner" v-model="tasksCheckbox"/>
						<span class="task-name">Dinner with Tony</span>
						<i class="pi pi-comments"></i>
					</li>
					<li>
						<Checkbox name="task" value="meeting" v-model="tasksCheckbox"/>
						<span class="task-name">Client Meeting</span>
						<i class="pi pi-users"></i>
					</li>
					<li>
						<Checkbox name="task" value="theme" v-model="tasksCheckbox"/>
						<span class="task-name">New Theme</span>
						<i class="pi pi-palette"></i>
					</li>
					<li>
						<Checkbox name="task" value="ticket" v-model="tasksCheckbox"/>
						<span class="task-name">Flight Ticket</span>
						<i class="pi pi-ticket"></i>
					</li>
					<li>
						<Checkbox name="task" value="charts" v-model="tasksCheckbox"/>
						<span class="task-name">Generate Charts</span>
						<i class="pi pi-chart-bar"></i>
					</li>
					<li>
						<Checkbox name="task" value="client" v-model="tasksCheckbox"/>
						<span class="task-name">Call Client</span>
						<i class="pi pi-mobile"></i>
					</li>
				</ul>
			</Panel>
		</div>

		<div class="col-12 md:col-6 lg:col-4 p-fluid contact-form">
			<Panel header="Contact Us">
				<div class="grid">
					<div class="col-12">
						<Dropdown v-model="dropdownCity" :options="dropdownCities" optionLabel="name" placeholder="Select a City"/>
					</div>
					<div class="col-12">
						<InputText type="text" placeholder="Name"/>
					</div>
					<div class="col-12">
						<InputText type="text" placeholder="Age"/>
					</div>
					<div class="col-12">
						<InputText type="text" placeholder="Email"/>
					</div>
					<div class="col-12">
						<Textarea placeholder="Messages"/>
					</div>
					<div class="col-12">
						<Button type="button" label="Send" icon="pi pi-check"/>
					</div>
				</div>
			</Panel>
		</div>

		<div class="col-12 lg:col-4 contacts">
			<Panel header="Team" style="min-height: 360px">
				<ul>
					<li class="clearfix">
						<img alt="User" src="layout/images/avatar/avatar.png" width="45"/>
						<div class="contact-info">
							<span class="name">Samantha Owens</span>
							<span class="location">jane@pn-apollo.com</span>
						</div>
						<div class="contact-actions">
							<Button icon="pi pi-comment"></Button>
							<Button icon="pi pi-reply" class="p-button-success"></Button>
						</div>
					</li>
					<li class="clearfix">
						<img alt="Contact 1" src="layout/images/avatar/avatar1.png" width="45"/>
						<div class="contact-info">
							<span class="name">Joshua Williams</span>
							<span class="location">joshua@pn-apollo.com</span>
						</div>
						<div class="contact-actions">
							<Button icon="pi pi-comment"></Button>
							<Button icon="pi pi-reply" class="p-button-success"></Button>
						</div>
					</li>
					<li class="clearfix">
						<img alt="Contact 2" src="layout/images/avatar/avatar2.png" width="45"/>
						<div class="contact-info">
							<span class="name">Emily Clark</span>
							<span class="location">emily@pn-apollo.com</span>
						</div>
						<div class="contact-actions">
							<Button icon="pi pi-comment"></Button>
							<Button icon="pi pi-reply" class="p-button-success"></Button>
						</div>
					</li>
					<li class="clearfix">
						<img alt="Contact 3" src="layout/images/avatar/avatar3.png" width="45"/>
						<div class="contact-info">
							<span class="name">Tim Johnson</span>
							<span class="location">tim@pn-apollo.com</span>
						</div>
						<div class="contact-actions">
							<Button icon="pi pi-comment"></Button>
							<Button icon="pi pi-reply" class="p-button-success"></Button>
						</div>
					</li>
					<li class="clearfix">
						<img alt="Contact 4" src="layout/images/avatar/avatar4.png" width="45"/>
						<div class="contact-info">
							<span class="name">David Stark</span>
							<span class="location">kelly@pn-apollo.com</span>
						</div>
						<div class="contact-actions">
							<Button icon="pi pi-comment"></Button>
							<Button icon="pi pi-reply" class="p-button-success"></Button>
						</div>
					</li>
				</ul>
			</Panel>
		</div>

		<div class="col-12 lg:col-8 chat">
			<Panel header="Chat" class="no-pad">
				<ul>
					<li class="clearfix message-from">
						<img alt="Contact 2" src="layout/images/avatar/avatar2.png"/>
						<span>Retro occupy organic, stumptown shabby chic pour-over roof party DIY normcore.</span>
					</li>
					<li class="clearfix message-own">
						<img alt="User" src="layout/images/avatar/avatar.png"/>
						<span>Actually artisan organic occupy, Wes Anderson ugh whatever pour-over gastropub selvage.</span>
					</li>
					<li class="clearfix message-from">
						<img alt="Contact 2" src="layout/images/avatar/avatar2.png"/>
						<span>Chillwave craft beer tote bag stumptown quinoa hashtag.</span>
					</li>
					<li class="clearfix message-own">
						<img alt="User" src="layout/images/avatar/avatar.png"/>
						<span>Dreamcatcher locavore iPhone chillwave, occupy trust fund slow-carb distillery art party narwhal.</span>
					</li>
					<li class="clearfix message-own">
						<span>Sed ut perspiciatis unde omnis iste natus.</span>
					</li>
					<li class="clearfix message-from">
						<img alt="Contact 2" src="layout/images/avatar/avatar2.png"/>
						<span>Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse.</span>
					</li>
					<li class="clearfix message-own">
						<img alt="User" src="layout/images/avatar/avatar.png"/>
						<span>At vero eos et accusamus.</span>
					</li>
                    <li class="clearfix message-own">
						<img alt="User" src="layout/images/avatar/avatar.png"/>
						<span>Et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque.</span>
					</li>
				</ul>
				<div class="new-message">
					<div class="message-attachment">
						<i class="pi pi-paperclip"></i>
					</div>
					<div class="message-input">
						<input type="text" placeholder="Write a message" class="p-inputtext"/>
					</div>
				</div>
			</Panel>

			<div class="sales-panel">
				<div class="card px-2 pt-2">
					<DataTable :value="products" responsiveLayout="scroll" :rows="5" style="margin-bottom: 20px" :paginator="true">
						<Column>
							<template #header>
								Logo
							</template>
							<template #body="slotProps">
								<img :src="'demo/images/product/' + slotProps.data.image" :alt="slotProps.data.image" width="50" />
							</template>
						</Column>
						<Column field="name" header="Name" :sortable="true">
							<template #body="slotProps">
                                <span class="p-column-title">Name</span>
                                {{slotProps.data.name}}
                            </template>
						</Column>
						<Column field="category" header="Category" :sortable="true">
							<template #body="slotProps">
                                <span class="p-column-title">Category</span>
                                {{slotProps.data.category}}
                            </template>
						</Column>
						<Column field="price" header="Price" :sortable="true">
							<template #body="slotProps">
                                <span class="p-column-title">Price</span>
                                {{ formatCurrency(slotProps.data.price) }}
                            </template>
						</Column>
						<Column>
							<template #body>
								<Button icon="pi pi-search" type="button" class="p-button-success mr-2 mb-1"></Button>
								<Button icon="pi pi-times" type="button" class="p-button-danger mb-1"></Button>
							</template>
						</Column>
					</DataTable>
				</div>
			</div>
		</div>

		<div class="col-12 lg:col-4">
			<div class="card timeline p-fluid">
				<div class="grid">
					<div class="col-3">
						<span class="event-time">just now</span>
						<i class="pi pi-fw pi-map-marker" style="color:#3984b8"></i>
					</div>
					<div class="col-9">
						<span class="event-owner" style="color:#3984b8">Katherine May</span>
						<span class="event-text">Lorem ipsun dolor amet</span>
						<div class="event-content">
							<img alt="Bridge" src="layout/images/dashboard/bridge.png" width="100"/>
						</div>
					</div>

					<div class="col-3">
						<span class="event-time">12h ago</span>
						<i class="pi pi-fw pi-star-fill" style="color:#f6ac2b"></i>
					</div>
					<div class="col-9">
						<span class="event-owner" style="color:#f6ac2b">Brandon Santos</span>
						<span class="event-text">Ab nobis, magnam sunt eum. Laudantium, repudiandae, similique!.</span>
					</div>

					<div class="col-3">
						<span class="event-time">15h ago</span>
						<i class="pi pi-fw pi-comment" style="color:#7e8dcd"></i>
					</div>
					<div class="col-9">
						<span class="event-owner" style="color:#7e8dcd">Stephan Ward</span>
						<span class="event-text">Omnis veniam quibusdam ratione est repellat qui nam quisquam ab mollitia dolores ullam voluptates, similique, dignissimos.</span>
					</div>

					<div class="col-3">
						<span class="event-time">2d ago</span>
						<i class="pi pi-fw pi-globe" style="color:#e175a0"></i>
					</div>
					<div class="col-9">
						<span class="event-owner" style="color:#e175a0">Jason Smith</span>
						<span class="event-text">Laudantium, repudiandae, similique!</span>
						<div class="event-content">
							<img alt="Map" src="layout/images/dashboard/map.png"/>
						</div>
					</div>

					<div class="col-3">
						<span class="event-time">1w ago</span>
						<i class="pi pi-fw pi-heart" style="color:#39b8b6"></i>
					</div>
					<div class="col-9">
						<span class="event-owner">Kevin Cox</span>
						<span class="event-text">Quibusdam ratione est repellat qui nam quisquam veniam quibusdam ratione.</span>
					</div>

					<div class="col-3">
						<span class="event-time">2w ago</span>
						<i class="pi pi-fw pi-compass" style="color:#3eb839"></i>
					</div>
					<div class="col-9">
						<span class="event-owner" style="color:#3eb839">Walter White</span>
						<span class="event-text">I am the one who knocks!</span>
					</div>

					<div class="col-12">
						<Button type="button" label="Refresh" icon="pi pi-refresh"
								class="rounded-btn raised-btn"></Button>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 md:col-6">
			<Panel header="Core 1 Data">
				<Chart type="line" :data="chartData"/>
			</Panel>
		</div>

		<div class="col-12 md:col-6">
			<Panel header="Disk Spaces" class="disk-spaces">
				<span>4.2 TB Used</span>
				<ProgressBar :value="75" :showValue="false"></ProgressBar>

				<span>2.9 TB Used</span>
				<ProgressBar :value="45" :showValue="false"></ProgressBar>

				<span>5.9 TB Used</span>
				<ProgressBar :value="85" :showValue="false"></ProgressBar>
			</Panel>
		</div>

		<div class="col-12 lg:col-4">
			<div class="user-card card">
				<div class="user-card-header">
					<Button icon="pi pi-plus" @click="toggle"/>
					<Menu :model="menuItems" :popup="true" ref="menu" appendTo="body"/>
				</div>
				<div class="user-card-content">
					<img src="layout/images/avatar/avatar.png" alt="apollo-layout"/>

					<div class="user-card-name">
						<span>Sarah Miller</span>
					</div>

					<div class="user-detail">
						<ul>
							<li class="clearfix">
								<i class="pi pi-fw pi-list"></i>
								<span class="project-title">Tasks</span>
								<span class="project-detail">3 open</span>
								<div class="project-progressbar">
									<div class="project-progressbar-value" style="width: 50%"></div>
								</div>
							</li>
							<li class="clearfix">
								<i class="pi pi-fw pi-chart-line"></i>
								<span class="project-title">Revenue</span>
								<span class="project-detail">+20%</span>
								<div class="project-progressbar">
									<div class="project-progressbar-value" style="width: 20%"></div>
								</div>
							</li>
							<li class="clearfix">
								<i class="pi pi-fw pi-ticket"></i>
								<span class="project-title">Payments</span>
								<span class="project-detail">24 new</span>
								<div class="project-progressbar">
									<div class="project-progressbar-value" style="width: 65%"></div>
								</div>
							</li>
							<li class="clearfix">
								<i class="pi pi-fw pi-users"></i>
								<span class="project-title">Clients</span>
								<span class="project-detail">+80%</span>
								<div class="project-progressbar">
									<div class="project-progressbar-value" style="width: 80%"></div>
								</div>
							</li>
							<li class="clearfix">
								<i class="pi pi-fw pi-sliders-h"></i>
								<span class="project-title">Turnover</span>
								<span class="project-detail">+40%</span>
								<div class="project-progressbar">
									<div class="project-progressbar-value" style="width: 40%"></div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<div class="card map">
				<h4>Map</h4>
				<img src="layout/images/dashboard/location.jpg" alt="apollo-layout"/>
			</div>
		</div>

		<div class="col-12 md:col-8">
			<Panel header="Calendar" style="height: 100%">
				<FullCalendar :events="events" :options="options"/>
			</Panel>
		</div>
	</div>
</template>

<script>
	import ProductService from '../service/ProductService';
	import EventService from '../service/EventService';
	import dayGridPlugin from '@fullcalendar/daygrid';
	import timeGridPlugin from '@fullcalendar/timegrid';
	import interactionPlugin from '@fullcalendar/interaction';

	export default {
		data() {
			return {
				tasksCheckbox: [],
				dropdownCities: [
					{name: 'New York', code: 'NY'},
					{name: 'Rome', code: 'RM'},
					{name: 'London', code: 'LDN'},
					{name: 'Istanbul', code: 'IST'},
					{name: 'Paris', code: 'PRS'}
				],
				dropdownCity: null,
				products: null,
				chartData: {
					labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
					datasets: [
						{
							label: 'First Dataset',
							data: [65, 59, 80, 81, 56, 55, 40],
							fill: false,
							borderColor: '#03A9F4',
							tension: .4
						},
						{
							label: 'Second Dataset',
							data: [28, 48, 40, 19, 86, 27, 90],
							fill: false,
							borderColor: '#FFC107',
							tension: .4
						}
					]
				},
				menuItems: [
					{
						label: 'Save', icon: 'pi pi-fw pi-check'
					},
					{
						label: 'Update', icon: 'pi pi-fw pi-refresh'
					},
					{
						label: 'Delete', icon: 'pi pi-fw pi-trash'
					}
				],
				options: {
					plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
					initialDate : '2023-01-01',
					headerToolbar: {
						left: 'prev,next today',
						center: 'title',
						right: 'dayGridMonth,timeGridWeek,timeGridDay'
					},
					editable: true,
					selectable:true, 
					selectMirror: true, 
					dayMaxEvents: true,
				},
				events: null,
			}
		},
		productService: null,
		eventService: null,
		created() {
			this.productService = new ProductService();
			this.eventService = new EventService();
		},
		mounted() {
			this.productService.getProductsSmall().then(data => this.products = data);
			this.eventService.getEvents().then(data => this.events = data);
		},
		methods: {
			toggle(event) {
				this.$refs.menu.toggle(event);
			},
			formatCurrency(value) {
				return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
			}
		}
	}
</script>


<style lang="scss" scoped>
::v-deep(.fc .fc-col-header-cell-cushion),
::v-deep(.fc-daygrid-dot-event .fc-event-time),
::v-deep(.fc-daygrid-dot-event .fc-event-title),
::v-deep(.fc .fc-daygrid-day-number),
::v-deep(.fc .fc-daygrid-more-link) {
	color: var(--text-color);
}
</style>
